import { defineStore } from "pinia";

export type FlagTypes = "campaign_new_applicants";
export interface FlagSocket {
  flag_type: FlagTypes;
  object_type: number;
  object_id: number;
  value: boolean;
}
export interface FlagSocketState {
  newApplicants: FlagSocket[];
  newContent: FlagSocket[];
}
export const useFlagSocketStore = defineStore("flagSocket", {
  state: (): FlagSocketState => ({
    newApplicants: [] as FlagSocket[],
    newContent: [] as FlagSocket[],
  }),
  actions: {
    __updateFlagState(flag: FlagSocket, keyState: string): void {
      const stateParam = Reflect.get(this, keyState);

      const index = stateParam.findIndex(
        (record: FlagSocket) => record.object_id === flag.object_id
      );
      if (index === -1) {
        stateParam.push(flag);
      } else {
        stateParam[index].value = flag.value;
      }
    },
    update(flag: FlagSocket): FlagSocket {
      if (flag.flag_type === "campaign_new_applicants") {
        this.__updateFlagState(flag, "newApplicants");
      } else if (flag.flag_type === "campaign_new_job_content_ready") {
        this.__updateFlagState(flag, "newContent");
      }
      return flag;
    },
  },
});
