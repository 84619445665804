import { DsLocalData } from "@devsalsa/vue-core";

import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import SubscriptionService from "@/modules/settings/services/subscription/SubscriptionService";
import CompanyService from "@/shared/services/CompanyService";

import { SubscriptionHelper } from "@/modules/settings/helpers/SubscriptionHelper";

export default class DashboardGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withPendingSubscription(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    to.meta.companyStats = await CompanyService.stats();

    const subscriptionPlan = DsLocalData("subscription.plan", "");
    if (subscriptionPlan.value !== "") {
      const successUrl = SubscriptionHelper.redirectUri("subscribed");
      const cancelUrl = SubscriptionHelper.redirectUri("dashboard");
      try {
        const response = await SubscriptionService.createLink(
          subscriptionPlan.value,
          successUrl,
          cancelUrl
        );
        window.open(response.link, "_self");
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        subscriptionPlan.value = "";
      }
    }

    next();
  }
}
