import { DsFlashNotifier } from "@devsalsa/vue-core";

import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import JobContentService from "@/modules/job/services/JobContentService";

import { BoostTypeId } from "@/modules/tiktok-boost/enums/BoostTypeEnum";

import BoostCreationLocalData from "@/modules/tiktok-boost/helpers/BoostCreationLocalData";

export default class TikTokBoostStepValidator {
  static async details() {
    const details = BoostCreationLocalData.details.value;
    if (!details) {
      return false;
    }

    const validate =
      details.name &&
      details.landing_page_url &&
      details.call_to_action &&
      details.tt_spark_code;
    if (!validate) {
      return false;
    }

    if (BoostCreationLocalData.boost.value === BoostTypeId.JobContent) {
      try {
        const jobId = BoostCreationLocalData.contentId.value;
        const content = await JobContentService.get(Number(jobId));
        if (!content) {
          return false;
        }
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }

        DsFlashNotifier.error(ErrorTranslator.translate(error));
        return false;
      }
    }

    return true;
  }

  static schedule() {
    const schedule = BoostCreationLocalData.schedule.value;
    if (!schedule) {
      return false;
    }

    return schedule.daily_budget && schedule.start_time && schedule.end_time;
  }

  static audience() {
    const audience = BoostCreationLocalData.audience.value;
    if (!audience) {
      return false;
    }

    return (
      audience.gender && audience.age && audience.device && audience.locations
    );
  }

  static payment() {
    if (!this.details()) {
      return "BoostDetails";
    }

    if (!this.schedule()) {
      return "BoostSchedule";
    }

    if (!this.audience()) {
      return "BoostAudience";
    }

    return "";
  }
}
