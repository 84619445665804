import type { DsIAuthService } from "@devsalsa/vue-core";

import { ConnectivityHandler } from "@/core/shared/helpers/Connectivity/ConnectivityHandler";
import { ApiErrorHandler } from "@/core/shared/helpers/Error/ApiErrorHandler";

import type {
  AxiosError,
  AxiosProgressEvent,
  AxiosRequestHeaders,
  AxiosResponse,
  GenericAbortSignal,
  Method,
} from "axios";
import axios from "axios";
import type { IAxiosRetryConfig } from "axios-retry";
import axiosRetry from "axios-retry";
import { container } from "tsyringe";

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiResponse
 * @memberOf ApiService
 * @property data {T} Data received from the API
 * @property ts {number} Timestamp for the response
 */
// eslint-disable-next-line
export interface ApiResponse<T = any> {
  data: T;
  ts: number;
}

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiSuccessResponse
 * @memberOf ApiService
 */
// eslint-disable-next-line
export interface ApiSuccessResponse<T = any> extends ApiResponse {}

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiErrorResponse
 * @memberOf ApiService
 * @property code {string} Error code sent by the API
 * @property message {string} Error message sent by the API
 */
// eslint-disable-next-line
export interface ApiErrorResponse<T = any> extends ApiResponse {
  code: string;
  message: string;
}

/**
 * @class ApiService
 */
export class ApiService {
  static readonly POST = "post";
  static readonly GET = "get";
  static readonly PUT = "put";
  static readonly DELETE = "delete";

  /**
   * Fetch data using axios
   *
   * @param method {string} Method (post, get, put, delete)
   * @param endpoint {string} URL Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders} HTTP Headers
   * @param axiosRetryConfig Define the retry strategy
   * @param onUploadProgress
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static async fetchData<ApiResponse>(
    method: Method,
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders,
    axiosRetryConfig?: IAxiosRetryConfig,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    const client = axios.create();
    if (axiosRetryConfig) {
      client.interceptors.response.use(
        (response: AxiosResponse) => {
          if (!ConnectivityHandler.isOnline()) {
            //Enable connectivity if we have response without error
            ConnectivityHandler.setOnline();
          }
          return response;
        },
        (error: AxiosError) => {
          if (!ConnectivityHandler.isOnline() && error.request.status > 0) {
            //Enable connectivity if we have response with any error
            ConnectivityHandler.setOnline();
          }
          throw error;
        }
      );

      axiosRetry(client, axiosRetryConfig);
    }
    const authService = container.resolve<DsIAuthService>("AuthService");
    const token = authService.getToken();
    const __headers = { ...headers } as AxiosRequestHeaders;
    if (token) {
      Reflect.set(__headers, "Authorization", `Bearer ${token}`);
    }
    return client
      .request({
        method: method,
        url: import.meta.env.VITE_APP_API_URL + endpoint,
        data: data,
        headers: __headers,
        onUploadProgress: onUploadProgress,
        signal: signal,
      })
      .then((response: AxiosResponse) => {
        return response.data.data;
      })
      .catch((error: AxiosError) => {
        ApiErrorHandler.handle(error);
      });
  }

  /**
   * Get HTTP method
   *
   * The get method has a default retry strategy, but you can overwrite it.
   *
   * @param endpoint {string} Endpoint
   * @param axiosRetryConfig
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static get<ApiResponse>(
    endpoint: string,
    axiosRetryConfig?: IAxiosRetryConfig,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.GET,
      endpoint,
      undefined,
      {} as AxiosRequestHeaders,
      axiosRetryConfig || this.getRetryStrategy(),
      undefined,
      signal
    );
  }

  /**
   * Post
   * @param endpoint {string} Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders}
   * @param axiosRetryConfig
   * @param onUploadProgress
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static post<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders,
    axiosRetryConfig?: IAxiosRetryConfig,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.POST,
      endpoint,
      data,
      headers,
      axiosRetryConfig,
      onUploadProgress,
      signal
    );
  }

  /**
   * Put
   *
   * @param endpoint {string} Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders}
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static put<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.PUT,
      endpoint,
      data,
      headers,
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Delete
   *
   * @param endpoint {string} Endpoint
   * @param data
   * @param signal
   * @return {Promise<ApiSuccessResponse>}
   */
  static delete<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    signal?: GenericAbortSignal
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.DELETE,
      endpoint,
      data,
      undefined,
      undefined,
      undefined,
      signal
    );
  }

  /**
   * Default retry strategy for the get method
   */
  static getRetryStrategy(): IAxiosRetryConfig {
    return {
      retries: 10,
      retryDelay: (retryCount: number): number => {
        //Intervals in seconds
        const intervals = [5, 5, 10, 15, 30, 60, 180, 300, 600, 1200, 1800];
        return intervals[retryCount] * 1000;
      },
      retryCondition: (error): boolean => {
        // Only Network Error
        return error.code === "ERR_NETWORK";
      },
      onRetry: (retryCount) => {
        if (retryCount > 0) {
          ConnectivityHandler.setOffline();
        }
      },
    };
  }
}
