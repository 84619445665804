import { createI18n } from "vue-i18n";

import { useMarketplace } from "@/shared/composables/Marketplace";

const { getLocale, datetimeFormats, numberFormats, messages } =
  useMarketplace();

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: getLocale(),
  fallbackLocale: "en-US",
  messages,
  datetimeFormats,
  numberFormats,
});
