<template>
  <div class="account-layout">
    <UnauthenticatedNavbar />
    <div class="account-layout-content">
      <div class="account-layout-wrapper">
        <div class="account-layout-wrapper-card">
          <div class="account-layout-wrapper-card-bg" :style="bgProps" />
          <div class="account-layout-wrapper-card-content">
            <RouterView v-slot="{ Component, route }">
              <transition name="fade" mode="out-in">
                <component :is="Component" :key="route.name" />
              </transition>
            </RouterView>
          </div>
        </div>
      </div>
    </div>
    <UnauthenticatedFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UnauthenticatedFooter from "@/core/shared/components/Layout/UnauthenticatedFooter.vue";
import UnauthenticatedNavbar from "@/core/shared/components/Navigation/UnauthenticatedNavbar.vue";

import backgroundImage from "@/assets/img/backgrounds/account.webp";

export default defineComponent({
  name: "AccountLayout",
  components: {
    UnauthenticatedFooter,
    UnauthenticatedNavbar,
  },
  data() {
    return {
      bgProps: {
        backgroundImage: `url(${backgroundImage})`,
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.account-layout {
  &-content {
    @apply flex justify-center items-center
    bg-gray-50 lg:bg-white
    mt-16
    min-h-[calc(100vh_-_192px)] sm:min-h-[calc(100vh_-_128px)];
  }

  &-wrapper {
    @apply w-full sm:w-3/5 sm:py-8 2xl:w-2/5;

    &-card {
      @apply flex flex-col sm:rounded-2xl sm:shadow-lg lg:flex-row lg:shadow-xl xl:shadow-2xl;

      &-bg {
        @apply hidden h-28 w-full rounded-t-none rounded-t-2xl bg-cover bg-center bg-origin-border sm:block sm:rounded-t-2xl lg:ml-0 lg:h-auto lg:w-1/3 lg:rounded-t-none lg:rounded-l-2xl;
      }

      &-content {
        @apply m-3 rounded-2xl bg-white p-4 shadow sm:m-0 sm:p-8 sm:shadow-none md:p-6 lg:w-2/3 xl:p-10;
      }
    }
  }
}
</style>
