<template>
  <DsSidebar
    v-model="isShowing"
    :title="$t('modules.notification.h1')"
    width-css-class="notifications-sidebar"
  >
    <template #body>
      <div class="notifications-sidebar-body">
        <div class="relative">
          <NotificationList
            v-if="!populating"
            :notifications="notifications"
            :total-records="totalRecords"
            is-sidebar
            :disabled-nav-sidebar="disabledNavSidebar"
            @notification-read="onNotificationRead"
            @close-sidebar="closeSidebar"
          />
          <DsSkeleton
            v-for="index in 10"
            v-else
            :key="index"
            height="110px"
            width="100%"
            class="mb-[1px] block h-[110px] !rounded-none"
          />
        </div>
        <div v-if="totalRecords > 0" class="notifications-sidebar-footer">
          <DsButton outline size="lg" @click="readAllNotifications">
            {{ $t("common.button.markAllRead") }}
          </DsButton>
          <DsLink
            outline
            size="lg"
            theme-display="button"
            :to="{ name: 'Notifications' }"
            @click.stop="closeSidebar"
          >
            {{ $t("common.button.showAll") }}
          </DsLink>
        </div>
      </div>
    </template>
  </DsSidebar>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  DsButton,
  DsFlashNotifier,
  DsLink,
  DsSidebar,
  DsSkeleton,
  useDsLiveNotifierStore,
} from "@devsalsa/vue-core";
import type { DsLiveNotification } from "@devsalsa/vue-core/dist/components/live-notifier/DsLiveNotifier.types";

import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import NotificationList from "@/modules/notification/components/NotificationList.vue";

import LiveNotificationService from "@/modules/notification/services/LiveNotificationService";

export default defineComponent({
  name: "NotificationsSidebar",
  components: {
    DsButton,
    NotificationList,
    DsLink,
    DsSkeleton,
    DsSidebar,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      populating: false,
      totalRecords: 0,
      notifications: [] as DsLiveNotification[],
      currentPage: 1,
      recordsPerPage: 50,
      flagOnlyOnce: true,
      unsubscribeLiveNotifier: () => {
        return;
      },
    };
  },
  computed: {
    isShowing: {
      get() {
        if (this.show && this.flagOnlyOnce) {
          this.setFlagOnlyOnce(false);
          this.populateData(this.currentPage);
        }
        return this.show;
      },
      set(val: boolean) {
        this.$emit("update:show", val);
      },
    },
    disabledNav(): boolean {
      return this.totalRecords <= 0;
    },
    disabledNavSidebar(): boolean {
      return this.disabledNav;
    },
  },
  async created() {
    this.unsubscribeLiveNotifier = useDsLiveNotifierStore().$onAction(
      ({ name, after }) => {
        after(() => {
          if (name === "setUnread") {
            this.setFlagOnlyOnce(true);
          }
        });
      }
    );
  },
  unmounted() {
    this.unsubscribeLiveNotifier();
  },
  methods: {
    closeSidebar() {
      this.isShowing = false;
    },
    async populateData(page: number) {
      this.populating = true;
      try {
        this.currentPage = page;
        const response = await LiveNotificationService.search(
          page - 1,
          this.recordsPerPage
        );
        this.totalRecords = response.total_rows;
        this.notifications = response.rows;
        this.populating = false;
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        DsFlashNotifier.error(ErrorTranslator.translate(error));
      }
    },
    async readAllNotifications() {
      this.populating = true;
      try {
        await LiveNotificationService.markReadAll();
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        DsFlashNotifier.error(ErrorTranslator.translate(error));
      } finally {
        await this.populateData(1);
        this.populating = false;
      }
    },
    async onNotificationRead(notificationId: number) {
      this.populating = true;
      try {
        await LiveNotificationService.markRead(notificationId);
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        DsFlashNotifier.error(ErrorTranslator.translate(error));
      } finally {
        await this.populateData(this.currentPage);
        this.populating = false;
      }
    },
    setFlagOnlyOnce(value: boolean) {
      this.flagOnlyOnce = value;
    },
  },
});
</script>

<style lang="scss" scoped>
.notifications-sidebar {
  &-body {
    @apply h-full relative;
  }

  &-header {
    @apply z-10 bg-white sticky top-0 flex h-16 items-start justify-between border-b border-gray-200 px-5;
  }

  &-footer {
    @apply z-10 bg-white sticky bottom-0 grid grid-cols-1 gap-x-5 p-5 space-y-4 sm:space-y-0 sm:grid-cols-2 border-t border-gray-200;
  }
}
</style>
