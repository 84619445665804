import type {
  ActionsStore,
  TypeStore,
  WebSocketOptions,
} from "@/core/shared/plugins/WebSocket/WebSocketClient.types";

import Emitter from "./Emitter";

export default class {
  private readonly connectionUrl: string; // Connection url
  private readonly opts: WebSocketOptions; // Custom parameters that the caller can pass in
  public reconnection: boolean; // Whether to enable reconnection
  private readonly reconnectionAttempts: number; // Maximum number of reconnections
  private readonly reconnectionDelay: number | number[]; // Reconnection interval
  private reconnectTimeoutId = 0; // Reconnect timeout id
  private reconnectionCount = 0; // Reconnected times
  // eslint-disable-next-line
  private readonly passToStoreHandler: any; // Processing function when transferring data
  // eslint-disable-next-line
  private readonly store: any; // Pass in vuex store when vuex is enabled
  // eslint-disable-next-line
  private readonly mutations: any; // Pass in the mutations in vuex when vuex is enabled
  public webSocket: WebSocket | undefined; // websocket connection
  private readonly typeStore: TypeStore;
  private readonly actions: ActionsStore | undefined; // Pass in the actions in pinia when pinia is enabled

  /**
   * Observer mode, websocket service core function package
   * @param connectionUrl
   * @param opts Other configuration items
   */
  constructor(connectionUrl: string, opts: WebSocketOptions = {}) {
    // If the URL starts with // to process it, add the correct websocket protocol prefix
    if (connectionUrl.startsWith("//")) {
      // If the current website is a https request, add the wss prefix, otherwise add the ws prefix
      const scheme = window.location.protocol === "https:" ? "wss" : "ws";
      connectionUrl = `${scheme}:${connectionUrl}`;
    }

    // Assign the processed url and opts to the internal variables of the current class
    this.connectionUrl = connectionUrl;
    this.opts = opts;
    this.reconnection = this.opts.reconnection || false;
    this.reconnectionAttempts = this.opts.reconnectionAttempts || Infinity;
    this.reconnectionDelay = this.opts.reconnectionDelay || 1000;
    this.passToStoreHandler = this.opts.passToStoreHandler;
    this.typeStore = this.opts.typeStore || "pinia";

    // Establish connection
    this.connect(connectionUrl, opts);

    // If store is passed in the configuration parameters, store will be assigned
    if (opts.store) {
      this.store = opts.store;
    }
    // If there is a synchronization processing function that passes vuex in the configuration parameters, assign mutations
    if (opts.mutations && opts.typeStore === "vuex") {
      this.mutations = opts.mutations;
    }

    // If there is a synchronization processing function that passes to pinia in the configuration parameters, assign actions
    if (opts.store && opts.actions && opts.typeStore === "pinia") {
      this.actions = opts.actions;
    }
    this.onEvent();

    window.addEventListener(
      "unhandledrejection",
      (event) => {
        this.onError(event.reason);
        event.preventDefault();
      },
      false
    );
  }

  // Connect websocket
  connect(connectionUrl: string, opts: WebSocketOptions = {}): WebSocket {
    // Get the protocol passed in the configuration parameter
    const protocol = opts.protocol || "";
    // If no protocol is passed, establish a normal websocket connection, otherwise, create a websocket connection with protocol
    this.webSocket =
      opts.webSocket ||
      (protocol === ""
        ? new WebSocket(connectionUrl)
        : new WebSocket(connectionUrl, protocol));

    if (import.meta.env.VITE_APP_ENVIRONMENT === "development") {
      console.log("CONNECT WEBSOCKET => ", this.webSocket);
    }

    // Enable json sending
    if (!("sendObj" in (this.webSocket as WebSocket))) {
      // Convert the message to send into a JSON string
      (this.webSocket as WebSocket).sendObj = (obj: unknown) =>
        (this.webSocket as WebSocket).send(JSON.stringify(obj));
    }
    return this.webSocket;
  }
  // Reconnect
  reconnect(): void {
    // Reconnect when the number of reconnections is less than or equal to the set connection times
    if (this.reconnectionCount <= this.reconnectionAttempts) {
      this.reconnectionCount++;
      // Clear the timer of the last reconnection
      clearTimeout(this.reconnectTimeoutId);
      // Try to reconnect
      this.reconnectTimeoutId = window.setTimeout(
        () => {
          // If vuex is enabled, the reconnection method in vuex is triggered
          if (this.store) {
            this.passToStore("SOCKET_RECONNECT", this.reconnectionCount);
          }
          // Reconnect
          this.connect(this.connectionUrl, this.opts);
          // Trigger Web Socket events
          this.onEvent();
        },
        typeof this.reconnectionDelay === "number"
          ? this.reconnectionDelay
          : this.reconnectionDelay[this.reconnectionCount] * 1000
      );
    } else {
      // If vuex is enabled, the reconnection failure method is triggered
      if (this.store) {
        this.passToStore("SOCKET_RECONNECT_ERROR", true);
      }
    }
  }

  // Event distribution
  onEvent(): void {
    ["onmessage", "onclose", "onerror", "onopen"].forEach(
      (eventType: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line
        (this.webSocket as WebSocket)[eventType] = (event: any) => {
          Emitter.emit(eventType, event);

          // Call the corresponding method in vuex
          if (this.store) {
            try {
              this.passToStore("SOCKET_" + eventType, event);
            } catch (error) {
              this.onError(error);
            }
          }

          // Execute when the event is onopen during the reconnection state
          if (this.reconnection && eventType === "onopen") {
            // Setting example
            if (this.opts.$setInstance) {
              this.opts.$setInstance(event.currentTarget);
            }
            // Empty reconnection times
            this.reconnectionCount = 0;
          }

          // If during the reconnection and the event is onclose, call the reconnect method
          if (this.reconnection && eventType === "onclose") {
            this.reconnect();
          }
        };
      }
    );
  }

  /**
   * Trigger methods in vuex
   * @param eventName
   * @param event
   */
  // eslint-disable-next-line
  passToStore(eventName: string, event: any): void {
    // If there is an event processing function in the parameter, the custom event processing function is executed, otherwise the default processing function is executed
    if (this.passToStoreHandler) {
      this.passToStoreHandler(
        eventName,
        event,
        this.defaultPassToStore.bind(this)
      );
    } else {
      if (this.typeStore === "pinia") {
        this.passToStoreForPinia(eventName, event);
      } else {
        this.defaultPassToStore(eventName, event);
      }
    }
  }

  /**
   * The default event handler
   * @param eventName
   * @param event
   */
  defaultPassToStore(
    eventName: string,
    event: {
      data: string;
      mutation: string;
      namespace: string;
      action: string;
    }
  ): void {
    // If the beginning of the event name is not SOCKET_ then terminate the function
    if (!eventName.startsWith("SOCKET_")) {
      return;
    }
    let method = "commit";
    // Turn the letter of the event name to uppercase
    let target = eventName.toUpperCase();
    // Message content
    let msg = event;
    // data exists and the data is in JSON format
    if (event.data) {
      // Convert data from json string to json object
      msg = JSON.parse(event.data);
      // Determine whether msg is synchronous or asynchronous
      if (msg.mutation) {
        target = [msg.namespace || "", msg.mutation]
          .filter((e: string) => !!e)
          .join("/");
      } else if (msg.action) {
        method = "dispatch";
        target = [msg.namespace || "", msg.action]
          .filter((e: string) => !!e)
          .join("/");
      }
    }
    if (this.mutations) {
      target = this.mutations[target] || target;
    }
    // Trigger the method in the store
    this.store[method](target, msg);
  }

  passToStoreForPinia(
    eventName: string,
    event: {
      data: string;
      mutation: string;
      namespace: string;
      action: string;
    }
  ): void {
    // If the beginning of the event name is not SOCKET_ then terminate the function
    if (!eventName.startsWith("SOCKET_")) {
      return;
    }
    // Turn the letter of the event name to uppercase
    const target = eventName.toUpperCase();
    // Message content
    let msg = event;
    // data exists and the data is in JSON format
    if (event.data) {
      // Convert data from json string to json object
      msg = JSON.parse(event.data);
    }

    // Trigger the method in the store
    if (this.actions && Reflect.has(this.actions, target)) {
      Reflect.get(this.actions, target)(msg);
    }
    return;
  }

  onError(error: unknown): void {
    if (typeof this.opts.errorHandler === "function") {
      this.opts.errorHandler(error, null, "");
    } else {
      throw error;
    }
  }
}

// Extended global object
declare global {
  // Extend websocket object, add send Obj method
  interface WebSocket {
    sendObj(obj: unknown): void;
  }
}
