import AccountGuard from "@/core/modules/account/helpers/AccountGuard";

import {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";
import TikTokBoostStepGuard from "@/modules/tiktok-boost/helpers/step/TikTokBoostStepGuard";

export default [
  {
    path: "/boost",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "",
        redirect: { name: "TiktokAdCenter" },
      },
      {
        path: "create",
        name: "BoostDetails",
        component: () =>
          import("@/modules/tiktok-boost/views/BoostDetails.vue"),
        beforeEnter: TikTokBoostStepGuard.creation,
        meta: {
          title: "modules.boost.details.meta",
          stepperPosition: 0,
          scope: ScopeType.AdCenter,
          permission: PermissionType.Full,
        },
      },
      {
        path: "schedule",
        name: "BoostSchedule",
        component: () =>
          import("@/modules/tiktok-boost/views/BoostSchedule.vue"),
        beforeEnter: TikTokBoostStepGuard.schedule,
        meta: {
          title: "modules.boost.schedule.meta",
          stepperPosition: 1,
          scope: ScopeType.AdCenter,
          permission: PermissionType.Full,
        },
      },
      {
        path: "audience",
        name: "BoostAudience",
        component: () =>
          import("@/modules/tiktok-boost/views/BoostAudience.vue"),
        beforeEnter: TikTokBoostStepGuard.audience,
        meta: {
          title: "modules.boost.audience.meta",
          stepperPosition: 2,
          scope: ScopeType.AdCenter,
          permission: PermissionType.Full,
        },
      },
      {
        path: "payment",
        name: "BoostPayment",
        component: () =>
          import("@/modules/tiktok-boost/views/BoostPayment.vue"),
        beforeEnter: TikTokBoostStepGuard.payment,
        meta: {
          title: "modules.boost.payment.meta",
          stepperPosition: 3,
          scope: ScopeType.AdCenter,
          permission: PermissionType.Full,
        },
      },
    ],
  },
];
