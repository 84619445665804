import AccountGuard from "@/core/modules/account/helpers/AccountGuard";

import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/jobs",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "",
        name: "Jobs",
        component: () => import("@/modules/job/views/TheJobs.vue"),
        meta: {
          title: "modules.job.jobs.meta.title",
          cssClasses: ["authenticated-layout-content-white"],
          scope: ScopeType.Campaigns,
        },
      },
    ],
  },
];
