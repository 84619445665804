import AccountGuard from "@/core/modules/account/helpers/AccountGuard";

import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "/campaigns/recurring",
        name: "RecurringCampaigns",
        component: () =>
          import("@/modules/recurring-campaigns/views/RecurringCampaigns.vue"),
        meta: {
          title: "modules.recurringCampaigns.meta.title",
          cssClasses: ["authenticated-layout-content-white"],
          scope: ScopeType.RecurringCampaigns,
        },
      },
      {
        path: "/campaign/recurring/:campaignId(\\d+)",
        name: "RecurringCampaignSummary",
        component: () =>
          import(
            "@/modules/recurring-campaigns/views/RecurringCampaignSummary.vue"
          ),
        meta: {
          title: "modules.recurringCampaign.meta.title",
          cssClasses: ["authenticated-layout-content-white"],
          scope: ScopeType.RecurringCampaigns,
        },
      },
      {
        path: "/campaign/recurring/:campaignId(\\d+)/update-payment-method",
        name: "UpdateRecurringCampaignPaymentMethod",
        component: () =>
          import("@/modules/recurring-campaigns/views/UpdatePaymentMethod.vue"),
        meta: {
          title: "modules.recurringCampaign.meta.title",
          scope: ScopeType.PaymentMethods,
          //cssClasses: ["authenticated-layout-content-white"],
        },
      },
    ],
  },
];
