<template>
  <div
    :class="[
      'notification-card',
      {
        'notification-card-bg': readNotification,
      },
      { 'notification-card-sidebar': isSidebar },
    ]"
    data-testid="notification-card"
    @click="goToAction(notification.id)"
  >
    <DsImageCloud
      :class="[
        'notification-card-image',
        { 'notification-card-sidebar-image': isSidebar },
      ]"
      :src="notification.image_url"
      :alt="notification.title"
    />
    <div class="notification-card-content">
      <div class="notification-card-content-title">
        <span>{{ notification.title }}</span>
        <Component
          :is="iconType.icon"
          v-if="notification.app_notification_style && iconType"
          :class="['notification-card-content-icon', iconType.fill]"
        />
        <time
          :class="['notification-card-content-time', { 'ml-1': !isSidebar }]"
        >
          {{ createdOn }}
        </time>
      </div>
      <p
        :class="[
          'notification-card-description',
          { open: !descriptionCropped },
        ]"
      >
        <template v-if="descriptionMore && descriptionCropped">
          <span v-html="notification.message.slice(0, descriptionLimit)"></span>
        </template>
        <template v-else>
          <span v-html="notification.message"></span>
        </template>
      </p>
      <button
        v-if="descriptionMore"
        class="notification-card-more"
        @click.stop="descriptionCropped = !descriptionCropped"
      >
        <span v-if="descriptionCropped">
          {{ $t("common.button.expand") }}
        </span>
        <span v-else> {{ $t("common.button.collapse") }} </span>
        <DsChevronDownSolidIcon
          :class="[
            'notification-card-more-icon',
            { 'notification-card-more-show': !descriptionCropped },
          ]"
        />
      </button>
    </div>
    <div v-if="!isSidebar" class="notification-card-menu" @click.stop>
      <DsDropdown :items="dropdownItems" size="sm">
        <template #button>
          <DsDotsVerticalSolidIcon class="notification-card-menu-icon" />
        </template>
      </DsDropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { DsDropdownItem, DsLiveNotification } from "@devsalsa/vue-core";
import {
  DsAlertOutlineIcon,
  DsChevronDownSolidIcon,
  DsCircleCheckSolidIcon,
  DsDotsVerticalSolidIcon,
  DsDropdown,
  DsImageCloud,
  DsLiveToastStyleEnum,
} from "@devsalsa/vue-core";

import Datetime from "@/core/shared/helpers/Datetime";

import type { PropType } from "vue";

import NotificationRouterLink from "@/modules/notification/helpers/NotificationRouterLink";

interface IconData {
  icon: string;
  fill: string;
}

export default defineComponent({
  name: "NotificationCard",
  components: {
    DsChevronDownSolidIcon,
    DsDotsVerticalSolidIcon,
    DsDropdown,
    DsImageCloud,
    DsCircleCheckSolidIcon,
    DsAlertOutlineIcon,
  },
  props: {
    notification: {
      type: Object as PropType<DsLiveNotification>,
      required: true,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["markRead", "delete", "closeSidebar"],
  data() {
    return {
      descriptionLimit: 80,
      descriptionCropped: true,
      dropdownItems: [] as DsDropdownItem[],
    };
  },
  computed: {
    iconType(): IconData {
      if (
        this.notification.app_notification_style ===
        DsLiveToastStyleEnum.Success
      ) {
        return { icon: "DsCircleCheckSolidIcon", fill: "text-green-400" };
      }
      if (
        this.notification.app_notification_style ===
        DsLiveToastStyleEnum.Critical
      ) {
        return { icon: "DsAlertOutlineIcon", fill: "text-red-500" };
      }
      return {} as IconData;
    },
    createdOn() {
      return this.notification.date_created
        ? Datetime.formatUnixTime(this.notification.date_created)
        : "";
    },
    descriptionMore(): boolean {
      return (
        this.isSidebar &&
        Number(this.notification.message.length) > this.descriptionLimit
      );
    },
    readNotification(): boolean {
      return Number(this.notification.date_read) > 0;
    },
  },
  created() {
    this.setDropdownItems();
    if (window.innerWidth < 640) {
      this.descriptionLimit = 115;
    }
  },
  methods: {
    goToAction(notificationId: number): void {
      if (this.isSidebar) {
        if (this.notification.date_read === 0) {
          this.$emit("markRead", notificationId);
        }
        this.$emit("closeSidebar");
      }
      this.$router.push(NotificationRouterLink.get(this.notification));
    },
    setDropdownItems() {
      this.dropdownItems = [
        {
          label: this.$t("common.button.markRead"),
          action: () => this.$emit("markRead", this.notification.id),
          cssClasses: this.readNotification ? "hidden" : "pt-4 text-gray-900",
        },
        {
          label: this.$t("common.button.removeFromList"),
          action: () => this.$emit("delete", this.notification.id),
          cssClasses: this.readNotification
            ? "last:py-3 text-gray-900"
            : "pb-4 text-gray-900",
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.notification-card {
  @apply p-4 border-b relative sm:p-6 sm:flex hover:bg-gray-50 cursor-pointer;

  &-image {
    @apply object-contain shrink-0 rounded mb-3 w-11 h-11 sm:mb-0 sm:mr-6 sm:w-[72px] sm:h-[72px];
  }

  &-sidebar {
    @apply sm:p-5;
    &-image {
      @apply sm:w-11 sm:h-11 sm:mr-4;
    }
  }

  &-content {
    @apply break-words sm:max-w-[calc(100%_-_60px)];

    &-title {
      @apply font-medium text-sm text-gray-900 mb-1 flex space-x-1;
    }

    &-icon {
      @apply w-4 h-4 shrink-0;
    }

    &-time {
      @apply text-gray-500 whitespace-nowrap text-xs font-normal;
    }
  }

  &-more {
    @apply flex items-center font-medium text-sm text-gray-900 mt-2;

    &-icon {
      @apply align-middle w-4 h-4 ml-1 transition duration-300;
    }

    &-show {
      @apply rotate-180;
    }
  }

  &-menu {
    @apply absolute top-4 right-2 sm:top-6 sm:right-4;

    &-icon {
      @apply h-5 w-5 block fill-gray-900;
    }
  }

  &-bg {
    @apply bg-teal-50;
  }

  &-description {
    @apply max-h-16 sm:max-h-10 overflow-hidden text-gray-500 text-sm font-normal;

    &.open {
      @apply max-h-full transition-all duration-500;
    }

    :deep(a) {
      @apply text-blue-600 hover:underline block mt-2 text-sm;
    }
  }
}
</style>
