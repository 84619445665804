<template>
  <DsExternalLink :href="href" :target="target" class="external-footer-link">
    <slot></slot>
  </DsExternalLink>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DsExternalLink } from "@devsalsa/vue-core";

export default defineComponent({
  name: "ExternalFooterLink",
  components: {
    DsExternalLink,
  },
  props: {
    href: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.external-footer-link {
  @apply text-gray-500 text-sm lg:text-base no-underline hover:underline focus:underline;
}
</style>
