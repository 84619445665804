import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import RecurringCampaignService from "@/modules/campaign/services/RecurringCampaignService";

export default class RecurringMarketplaceGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withRecurringCampaign(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    try {
      const campaignId = Number(to.params.campaignId);
      to.meta.recurringCampaign =
        await RecurringCampaignService.get(campaignId);
    } catch (error) {
      if (!(error instanceof BadRequestApiServiceError)) {
        throw error;
      }

      return next({
        name: "Dashboard",
      });
    }

    next();
  }
}
