import { DsFlashNotifier } from "@devsalsa/vue-core";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import { MaintenanceHandler } from "@/core/shared/helpers/Maintenance/MaintenanceHandler";
import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import {
  MaintenanceApiServiceError,
  UnauthorizedApiServiceError,
  UnauthorizedTwoFactorAuthenticationApiServiceError,
} from "@/core/shared/services/Error/ApiServiceError";

import router from "@/router";

export class ErrorHandler {
  static handle(error: unknown): void {
    if (error instanceof UnauthorizedApiServiceError) {
      this.handleUnauthorized(error);
    } else if (
      error instanceof UnauthorizedTwoFactorAuthenticationApiServiceError
    ) {
      this.handleUnauthorizedTwoFactorAuthentication();
    } else if (error instanceof MaintenanceApiServiceError) {
      this.handleMaintenance(error);
    }
  }

  protected static handleUnauthorized(error: ApiServiceError): void {
    if (AccountHandler.isAuthenticated()) {
      AccountHandler.logout();
    }
    if (router.currentRoute.value.name !== "Login") {
      router.push({
        name: "Login",
      });
    }
    DsFlashNotifier.error(ErrorTranslator.translate(error));
  }

  protected static handleUnauthorizedTwoFactorAuthentication(): void {
    if (router.currentRoute.value.name !== "TwoFactorAuthentication") {
      router.push({
        name: "TwoFactorAuthentication",
      });
    }
  }

  protected static handleMaintenance(error: ApiServiceError): void {
    MaintenanceHandler.setOn(error);
  }
}
