import { ApiService } from "@/core/shared/services/ApiService";

import type {
  Chat,
  ChatMessageFormData,
  ChatMessageInfo,
  ChatMessageReadFormData,
  ResponseMessageReadUpdated,
  ResponseMessageUnreadUpdated,
} from "@/shared/services/ChatService.types";

/**
 * @class ChatService
 */
export default class ChatService {
  /**
   * Get chat information
   * @endpoint /company/chats/{id}
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<Chat>
   */
  static get(chatId: number): Promise<Chat> {
    return ApiService.get(`/company/chats/${chatId}`);
  }

  /**
   * Send company message
   * @endpoint /company/chats/messages
   * @httpMethod POST
   * @throws {ApiServiceError}
   * @return Promise<ChatMessageInfo>
   */
  static sendMessage(data: ChatMessageFormData): Promise<ChatMessageInfo> {
    return ApiService.post("/company/chats/messages", { ...data });
  }

  /**
   * Get history company
   * @endpoint /company/chats/{id}/messages
   * @httpMethod GET
   * @throws {ApiServiceError}
   * @return Promise<ChatMessageInfo[]>
   */
  static getHistory(
    chatId: number,
    sequenceId = 0
  ): Promise<ChatMessageInfo[]> {
    return ApiService.get(`/company/chats/${chatId}/messages/${sequenceId}`);
  }

  /**
   * Send company message
   * @endpoint /company/chats/messages/read
   * @httpMethod POST
   * @throws {ApiServiceError}
   * @return Promise<ChatMessageInfo>
   */
  static messageRead(
    data: ChatMessageReadFormData
  ): Promise<ResponseMessageReadUpdated> {
    return ApiService.put("/company/chats/messages/read", { ...data });
  }

  static markUnread(chatId: number): Promise<ResponseMessageUnreadUpdated> {
    return ApiService.put("/company/chats/messages/unread", {
      chat_id: chatId,
    });
  }
}
