import { DsCurrency } from "@devsalsa/vue-core";

import type { App } from "vue";

declare module "vue" {
  interface ComponentCustomProperties {
    /**
     * @remarks
     * The locale currency of localization.
     *@methods format(cents: number)
     *@methods fromCents(cents: number)
     *@methods toCents(amount: number)
     */
    $currency: typeof DsCurrency;
  }
}

export default {
  install(app: App) {
    // configure the app
    app.config.globalProperties.$currency = DsCurrency;
  },
};
