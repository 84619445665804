import AccountGuard from "@/core/modules/account/helpers/AccountGuard";

import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/campaign/:campaignId(\\d+)",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "",
        name: "CampaignSummary",
        component: () =>
          import("@/modules/campaign/information/views/CampaignSummary.vue"),
        meta: {
          title: "modules.campaign.productInformation.meta",
          scope: ScopeType.Campaigns,
        },
      },
      {
        path: "update-payment-method",
        name: "UpdateCampaignPaymentMethod",
        component: () =>
          import(
            "@/modules/campaign/information/views/UpdateCampaignPaymentMethod.vue"
          ),
        meta: {
          title: "modules.campaign.productInformation.meta",
          scope: ScopeType.PaymentMethods,
        },
      },
      {
        path: "resubmit",
        name: "CampaignResubmit",
        component: () =>
          import("@/modules/campaign/information/views/CampaignResubmit.vue"),
        meta: {
          title: "modules.campaign.resubmit.meta",
          scope: ScopeType.Campaigns,
        },
      },
    ],
  },
];
