import AccountGuard from "@/core/modules/account/helpers/AccountGuard";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/campaign",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "",
        redirect: { name: "Dashboard" },
      },
      {
        path: "builder/:brandId(\\d+)?/:productId(\\d+)?",
        name: "CampaignBuilder",
        component: () =>
          import("@/modules/campaign/builder/views/CampaignBuilder.vue"),
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext
        ) => {
          to.meta.prevRoute = from;
          return next();
        },
        meta: {
          title: "modules.campaign.details.meta",
        },
      },
      {
        path: "created",
        name: "CampaignCreated",
        component: () =>
          import("@/modules/campaign/creation/views/CampaignCreated.vue"),
        meta: {
          title: "modules.campaign.created.meta",
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
      {
        path: "cancel",
        name: "CampaignCancellation",
        component: () =>
          import("@/modules/campaign/creation/views/CampaignCancellation.vue"),
        meta: {
          title: "modules.campaign.cancel.meta",
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
    ],
  },
];
