import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

import { Onboarding } from "@/modules/onboarding/shared/Onboarding";

export default [
  {
    path: "/onboarding/",
    beforeEnter: OnboardingGuard.withIncompleteOnboarding,
    children: [
      {
        path: "",
        name: "CompanyInformation",
        component: () =>
          import("@/modules/onboarding/views/CompanyInformation.vue"),
        meta: {
          title: "modules.onboarding.companyInformation.meta",
          Navbar: true,
          stepperPosition: Onboarding.COMPANY,
          layout: "OnboardingLayout",
        },
      },
    ],
  },
];
