import { toRaw } from "vue";

import type { DsSelectOption } from "@devsalsa/vue-core";
import { DsCurrency, DsLocalData } from "@devsalsa/vue-core";

import Datetime from "@/core/shared/helpers/Datetime";

import type { JobContent } from "@/modules/job/services/JobContentService.types";
import type { AdResponse } from "@/modules/tiktok-ad-center/services/TiktokAdService.types";
import type { BoostCreationFormData } from "@/modules/tiktok-boost/services/BoostService.types";

import { AudienceModeType } from "@/modules/tiktok-boost/enums/AudienceModeTypeEnum";
import { BoostTypeId } from "@/modules/tiktok-boost/enums/BoostTypeEnum";
import { TiktokDevice } from "@/modules/tiktok-boost/enums/TiktokDeviceEnum";

export default class BoostCreationLocalData {
  private static defaultDetails() {
    return {
      name: "",
      landing_page_url: "",
      call_to_action: "",
      tt_spark_code: "",
    };
  }

  private static defaultAudience() {
    return {
      gender: "GENDER_UNLIMITED",
      age: ["AGE_25_34", "AGE_35_44", "AGE_45_54", "AGE_55_100"],
      device: [TiktokDevice.Android, TiktokDevice.IOS],
      interests_keywords: [] as DsSelectOption[],
      interactions_keywords: [] as DsSelectOption[],
      mode: AudienceModeType.Basic,
      locations: [] as DsSelectOption[],
    };
  }

  private static defaultSchedule() {
    return {
      daily_budget: 50,
      start_time: Datetime.setFormatDate(new Date(), 1),
      end_time: Datetime.setFormatDate(new Date(), 7),
      platform_fee_percent: 10,
    };
  }

  private static defaultPayment() {
    return {
      credit_card_id: 0,
      use_wallet: false,
    };
  }

  static details = DsLocalData("boost.details", this.defaultDetails());
  static schedule = DsLocalData("boost.schedule", this.defaultSchedule());
  static audience = DsLocalData("boost.audience", this.defaultAudience());
  static payment = DsLocalData("boost.payment", this.defaultPayment());
  static boost = DsLocalData("boost.boost", 1 as BoostTypeId);
  static contentId = DsLocalData("boost.contentId", 0);
  static tiktokVideo = DsLocalData("boost.tiktokVideo", {} as JobContent);
  private static position = DsLocalData("boost.position", 0);

  static initDetailsWithDefaultValues() {
    BoostCreationLocalData.boost.value = BoostTypeId.JobContent;
  }

  static initAudienceWithDefaultValues() {
    BoostCreationLocalData.audience.value = {
      ...this.defaultAudience(),
      locations: [{ value: "6252001", text: "United states" }],
    };
  }

  static resetDetailsDefaultValues() {
    BoostCreationLocalData.details.value.tt_spark_code = "";
    BoostCreationLocalData.contentId.value = 0;
    BoostCreationLocalData.tiktokVideo.value = {} as JobContent;
  }

  static resetAudienceDefaultValues(mode: AudienceModeType) {
    BoostCreationLocalData.audience.value = {
      ...this.defaultAudience(),
      mode: mode,
      locations: [{ value: "6252001", text: "United states" }],
    };
  }

  static setPosition(val: string, update = false) {
    const positionRoute = [
      "BoostDetails",
      "BoostSchedule",
      "BoostAudience",
      "BoostPayment",
    ];

    const key: number =
      positionRoute.includes(val) && positionRoute.indexOf(val)
        ? positionRoute.indexOf(val)
        : 0;

    if (key > BoostCreationLocalData.position.value || update) {
      BoostCreationLocalData.position.value = key;
    }
    return val;
  }

  static getPosition() {
    return BoostCreationLocalData.position.value;
  }

  static clear() {
    this.details.value = this.defaultDetails();
    this.schedule.value = this.defaultSchedule();
    this.audience.value = this.defaultAudience();
    this.payment.value = this.defaultPayment();
    this.position.value = 0;
    this.boost.value = BoostTypeId.JobContent;
    this.contentId.value = 0;
    this.tiktokVideo.value = {} as JobContent;
  }

  static prepareFormData(): BoostCreationFormData {
    const data = {
      name: this.details.value.name,
      landing_page_url: this.details.value.landing_page_url,
      call_to_action: this.details.value.call_to_action,
      tt_spark_code: this.details.value.tt_spark_code,

      daily_budget: DsCurrency.toCents(this.schedule.value.daily_budget),
      start_time: this.schedule.value.start_time,
      end_time: this.schedule.value.end_time,

      gender: this.audience.value.gender,
      tt_ad_age_group: toRaw(this.audience.value.age),
      tt_ad_locations: toRaw(
        this.audience.value.locations.map(
          (option: DsSelectOption) => option.value
        )
      ),
      tt_ad_operating_systems: toRaw(this.audience.value.device),

      credit_card_id: this.payment.value.credit_card_id,
      use_wallet: this.payment.value.use_wallet,
    } as BoostCreationFormData;

    if (this.boost.value === BoostTypeId.JobContent) {
      data.job_content_id = this.contentId.value;
    }

    return data;
  }

  static setBoost(boost: AdResponse) {
    let boostType = BoostTypeId.SparkCode;
    if (boost.job_content_id > 0) {
      boostType = BoostTypeId.JobContent;
    }
    this.boost.value = boostType;
    this.contentId.value = boost.job_content_id;
    this.details.value = {
      name: boost.name,
      landing_page_url: boost.landing_page_url,
      call_to_action: boost.call_to_action,
      tt_spark_code: boost.tt_spark_code,
    };
    this.schedule.value = {
      daily_budget: DsCurrency.fromCents(boost.daily_budget),
      start_time: "",
      end_time: "",
      platform_fee_percent: 10,
    };
    this.audience.value = {
      gender: boost.gender,
      age: boost.tt_ad_age_groups.map((option: DsSelectOption) =>
        String(option.value)
      ),
      locations: boost.tt_ad_locations,
      device: boost.tt_ad_operating_systems.map(
        (option: DsSelectOption) => option.value as TiktokDevice
      ),
      interests_keywords: boost.tt_ad_interests,
      interactions_keywords: boost.tt_ad_hashtags,
      mode: AudienceModeType.Advance,
    };

    if (boost.tt_ad_operating_systems.length === 0) {
      this.audience.value.device = [TiktokDevice.Android, TiktokDevice.IOS];
    }

    this.payment.value = {
      credit_card_id: boost.credit_card_id,
      use_wallet: boost.use_company_wallet,
    };
  }
}
