import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import ChatService from "@/shared/services/ChatService";

export default class ChatGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withValidChat(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (to.params.chatId) {
      try {
        to.meta.chat = await ChatService.get(Number(to.params.chatId));
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }

        return next({
          name: "TheChat",
        });
      }
    }

    return next();
  }
}
