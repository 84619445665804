import AccountGuard from "@/core/modules/account/helpers/AccountGuard";

import {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";

import MarketplaceGuard from "@/modules/campaign/marketplace/helpers/MarketplaceGuard";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/campaign/:campaignId(\\d+)",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      MarketplaceGuard.withCampaign,
      AccountGuard.withPermissions,
    ],
    children: [
      {
        path: "marketplace",
        name: "CampaignMarketplace",
        component: () =>
          import(
            "@/modules/campaign/marketplace/views/CampaignMarketplace.vue"
          ),
        meta: {
          title: "modules.campaign.marketplace.meta",
          scope: ScopeType.Campaigns,
          permission: PermissionType.Full,
        },
      },
    ],
  },
];
