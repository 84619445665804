import { ApiService } from "@/core/shared/services/ApiService";

import type {
  JobBalanceSheet,
  JobExtended,
} from "@/modules/job/services/JobService.types";

export default class JobService {
  /**
   * @param jobId
   */
  static get(jobId: number): Promise<JobExtended> {
    return ApiService.get(`/company/jobs/${jobId}`);
  }

  /**
   * @endpoint /job/${jobId}/balance-sheet/company
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   */
  static getBalanceSheet(jobId: number): Promise<JobBalanceSheet> {
    return ApiService.get(`/company/jobs/${jobId}/balance-sheet`);
  }
}
