import { ApiErrorTranslator } from "@/core/shared/helpers/Error/ApiErrorTranslator";
import { FirebaseErrorTranslator } from "@/core/shared/helpers/Error/FirebaseErrorTranslator";
import { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { FirebaseError } from "@firebase/util";

export class ErrorTranslator {
  static translate(error: ApiServiceError | FirebaseError): string {
    if (error instanceof ApiServiceError) {
      return ApiErrorTranslator.translate(error);
    }

    return FirebaseErrorTranslator.translate(error);
  }
}
