import UpsellGuard from "@/modules/job/helpers/UpsellGuard";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/job/:jobId(\\d+)",
    beforeEnter: OnboardingGuard.withCompleteOnboarding,
    children: [
      {
        path: "",
        name: "JobSummary",
        component: () =>
          import("@/modules/job/information/views/JobSummary.vue"),
        meta: {
          title: "modules.campaign.productInformation.meta",
        },
      },
      {
        path: "upsell",
        name: "JobUpsell",
        beforeEnter: UpsellGuard.validPurchasedItems,
        component: () =>
          import("@/modules/job/information/views/JobUpsell.vue"),
        meta: {
          title: "modules.job.upsell.meta",
        },
      },
      {
        path: "write-review",
        name: "WriteReview",
        redirect: { name: "JobSummary", hash: "#job-content" },
      },
    ],
  },
];
