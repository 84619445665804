import { ApiService } from "@/core/shared/services/ApiService";

import type { AxiosProgressEvent } from "axios";

import type {
  addHostnameResponse,
  AddWatermarkResponse,
  DeleteWatermarkResponse,
  Hostname,
  HostnameDeleted,
  HostnameVerified,
  ShareContentResponse,
} from "@/modules/settings/services/white-labeling/WhiteLabelingService.types";

export default class WhiteLabelingService {
  static async addWatermark(
    data: FormData,
    onUploadProgress: (event: AxiosProgressEvent) => void
  ): Promise<AddWatermarkResponse> {
    return ApiService.post(
      `/company/watermarks`,
      data,
      undefined,
      undefined,
      onUploadProgress
    );
  }

  static async deleteWatermark(): Promise<DeleteWatermarkResponse> {
    return ApiService.delete(`/company/watermarks`);
  }

  static addHostname(hostname: string): Promise<addHostnameResponse> {
    return ApiService.post(`/company/whitelabels/hostnames`, { hostname });
  }

  static deleteHostname(hostnameId: number): Promise<HostnameDeleted> {
    return ApiService.delete(`/company/whitelabels/hostnames/${hostnameId}`);
  }

  static verifyHostname(hostnameId: number): Promise<HostnameVerified> {
    return ApiService.get(
      `/company/whitelabels/hostnames/${hostnameId}/verify`
    );
  }

  static getHostnameList(): Promise<Hostname[]> {
    return ApiService.get(`/company/whitelabels/hostnames`);
  }

  static share(ids: number[]): Promise<ShareContentResponse> {
    return ApiService.post(`/company/whitelabels/share-content`, {
      job_content_ids: ids,
    });
  }
}
