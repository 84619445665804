import { useMarketplace } from "@/shared/composables/Marketplace";

export class SubscriptionHelper {
  private static baseUrl = "/settings/subscription";
  private static subscribedUrl = "/subscribed";
  private static paymentUpdated = "/payment-updated";

  public static redirectUri(path = ""): string {
    let redirectUrl = `https://${useMarketplace().currentMarketplace()}.${
      import.meta.env.VITE_APP_DOMAIN
    }${this.baseUrl}`;
    if (path === "subscribed") {
      redirectUrl = redirectUrl + this.subscribedUrl;
    }
    if (path === "payment") {
      redirectUrl = redirectUrl + this.paymentUpdated;
    }
    if (path === "dashboard") {
      redirectUrl = import.meta.env.VITE_APP_HOST;
    }
    return redirectUrl;
  }
}
