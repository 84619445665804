import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import JobService from "@/modules/job/services/JobService";

import type { JobExtended } from "@/modules/job/services/JobService.types";

import { JobStatus } from "@/shared/enums/JobStatusEnum";

export default class UpsellGuard {
  static async validPurchasedItems(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const jobId: unknown | number = to.params.jobId;
    const job: JobExtended = await JobService.get(jobId as number);
    const completedStatuses = [
      JobStatus.Completed as string,
      JobStatus.Finished as string,
    ];
    const isJobCompleted: boolean = completedStatuses.includes(
      job.status as JobStatus
    );

    if (isJobCompleted && job.nr_upsell > 0) {
      return next();
    }
    return next({
      name: "JobSummary",
      params: {
        jobId: job.id,
      },
      hash: "#job-content",
    });
  }
}
