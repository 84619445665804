export enum ScopeType {
  Campaigns = "campaigns",
  AdCenter = "tt_ad_center",
  Products = "products",
  Content = "content",
  Brands = "brands",
  Chat = "chat",
  Wallet = "wallet",
  Billing = "billing",
  PaymentMethods = "payment_methods",
  MySubscription = "my_subscription",
  WhiteLabeling = "white_labeling",
  AffiliateProgram = "affiliate_program",
  Collaborators = "company_collaborators",
  RecurringCampaigns = "recurring_campaigns",
}

export enum PermissionType {
  No = "no",
  View = "view",
  Full = "full",
}

export enum PermissionTypeStatusEnum {
  Blocked = "blocked",
  Current = "current",
  Selectable = "selectable",
  Hidden = "hidden",
}

export enum CollaboratorStatus {
  Invited = "invited",
  Accepted = "accepted",
}

export enum PermissionTooltip {
  require = "requires_dependencies",
  remove = "removes_dependencies",
}
