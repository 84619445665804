import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

import type {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";

export default class CollaboratorHelper {
  static checkPermission(scope: ScopeType, type: PermissionType[]): boolean {
    const accountInfo = AccountHandler.accountInfo.value as AccountInfo;

    if (
      accountInfo &&
      accountInfo.is_collaborator &&
      accountInfo.collaborator &&
      accountInfo.collaborator.scope_permissions
    ) {
      const permission = Reflect.get(
        accountInfo.collaborator.scope_permissions,
        scope
      );
      return type.includes(permission);
    }

    return true;
  }
}
