import { AuthErrorCodes } from "@firebase/auth";
import type { FirebaseError } from "@firebase/util";

import i18n from "@/i18n";

export class FirebaseErrorTranslator {
  static translate(error: FirebaseError): string {
    //We don't want to display those error messages when closing the social authentication modal.
    const excludeCodes = [
      AuthErrorCodes.POPUP_CLOSED_BY_USER,
      AuthErrorCodes.EXPIRED_POPUP_REQUEST,
      AuthErrorCodes.USER_CANCELLED,
      AuthErrorCodes.NETWORK_REQUEST_FAILED,
    ];

    if (error.code === AuthErrorCodes.NEED_CONFIRMATION) {
      return i18n.global.t("common.error.firebase.accountExists", {
        existingProvider: error.customData?.existingProvider,
        newProvider: error.customData?.newProvider,
        project: import.meta.env.VITE_APP_TITLE,
      });
    }

    if (excludeCodes.some((code) => code === error.code)) {
      return "";
    }

    return error.message;
  }
}
