import Grid from "@/core/shared/helpers/Grid";

import type { App } from "vue";

declare module "vue" {
  interface ComponentCustomProperties {
    /**
     * @remarks
     * The responsive Grid with tailwind breakpoints.
     *@methods $grid('block xl:hidden')
     */

    $grid: typeof Grid;
  }
}

export default {
  install(app: App) {
    // configure the app
    app.config.globalProperties.$grid = Grid;
  },
};
