import { ref, watch } from "vue";

const breakpoints: {
  [size: string]: number;
} = {
  xs: 300,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

const screenWidth = ref(window.innerWidth);
window.onresize = () => {
  screenWidth.value = window.innerWidth;
};

export default (args: string) => {
  const visible = ref(true);
  let visibleDefault = "block";
  const types = ["block", "hidden"];
  const minSizes: string[] = [];

  if (args) {
    const filters = args.split(" ");
    filters.forEach((val) => {
      const [size, type] = val.split(":");
      if (!type && types.includes(size)) {
        visibleDefault = size;
      }

      if (breakpoints[size] && types.includes(type)) {
        minSizes[breakpoints[size]] = type;
      }
    });
  }

  const validate = (width: number) => {
    let typeVisible = visibleDefault;
    minSizes.forEach((type, size) => {
      if (width >= size) {
        typeVisible = type;
      }
    });

    visible.value = typeVisible == "block";
  };

  validate(screenWidth.value);
  watch(() => screenWidth.value, validate, { deep: true });

  return visible.value;
};
