export enum JobStatus {
  CreatorAccepted = "creator_accepted",
  PurchasedSent = "purchased_sent",
  ProductReceived = "product_received",
  ContentDelivered = "content_delivered",
  ContentAdminApproved = "content_admin_approved",
  Canceled = "canceled",
  Completed = "completed",
  Finished = "finished",
  ContentAccepted = "content_accepted", //Only for TikTok UGC
  WaitingPublished = "creator_waiting_for_publish_date", // only for TikTok UGC
  Active = "active", // Apply for all Status except canceled/completed/closed
}
