import { DsClearLocalData } from "@devsalsa/vue-core";

import { VerificationCodeType } from "@/core/modules/account/enums/VerificationCodeTypeEnum";
import AccountService from "@/core/modules/account/services/AccountService";
import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import type { UnauthorizedTwoFactorAuthenticationApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { PermissionType } from "@/modules/settings/enums/CollaboratorsEnum";

export default class AccountGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withTwoFactorAuthenticationCode(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    try {
      let defaultMethod = VerificationCodeType.Email;
      let phoneId = 0;
      const methods = await AccountService.verificationMethods();
      to.meta.methods = methods;
      if (methods.phone_numbers.length > 0) {
        defaultMethod = VerificationCodeType.SMS;
        phoneId = methods.phone_numbers[0].id;
        to.meta.id = methods.phone_numbers[0].id;
      }
      if (to.params.id) {
        const phone = methods.phone_numbers.find(
          (phone) => phone.id === Number(to.params.id)
        );

        if (!phone) {
          defaultMethod = VerificationCodeType.Email;
          phoneId = 0;
          to.meta.id = 0;
        }
      }
      const response = await AccountService.resendTwoFactorAuthenticationCode(
        defaultMethod,
        phoneId
      );
      to.meta.seconds = response.seconds;
    } catch (error) {
      if (!(error instanceof BadRequestApiServiceError)) {
        throw error;
      }
      to.meta.seconds = (
        error as UnauthorizedTwoFactorAuthenticationApiServiceError
      ).data.seconds;
    }
    return next();
  }

  static async withImpersonationToken(to: RouteLocationNormalized) {
    //Clear all keys from local storage
    DsClearLocalData();
    await AccountHandler.authenticate(to.params.token.toString());
  }

  static async withPermissions(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    const { is_collaborator, collaborator } = AccountHandler.accountInfo
      .value as AccountInfo;
    const scope = to.meta.scope;
    const scopePermission = to.meta.permission;

    if (is_collaborator && collaborator && collaborator.scope_permissions) {
      const permission: PermissionType = Reflect.get(
        collaborator.scope_permissions,
        scope as string
      );

      if (scopePermission && permission !== scopePermission) {
        return next({
          name: "Dashboard",
        });
      }

      if (permission === PermissionType.No) {
        return next({
          name: "Dashboard",
        });
      }
    }

    next();
  }
}
