import { ApiService } from "@/core/shared/services/ApiService";

import type {
  JobContent,
  JobContentDownload,
} from "@/modules/job/services/JobContentService.types";

export default class JobContentService {
  /**
   *
   * @param job_content_id
   */
  static get(job_content_id: number): Promise<JobContent> {
    return ApiService.get(`/company/job-contents/${job_content_id}`);
  }

  /**
   *
   * @param job_content_id
   */
  static download(job_content_id: number): Promise<JobContentDownload> {
    return ApiService.get(`/company/job-contents/${job_content_id}/download`);
  }
}
