<template>
  <div :class="[{ 'flex justify-center items-center ': disabledNavSidebar }]">
    <template v-if="totalRecords > 0">
      <div
        :class="[
          'relative flex-1',
          {
            'border-l border-r sm:border-l-0 sm:border-r-0 border-t':
              !isSidebar,
          },
        ]"
      >
        <NotificationCard
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          :is-sidebar="isSidebar"
          @mark-read="onMarkRead"
          @delete="onDelete"
          @close-sidebar="$emit('closeSidebar')"
        />
      </div>
    </template>
    <template v-else>
      <div
        :class="[
          'text-center',
          'min-h-[calc(100vh-149px)] relative flex flex-col items-center justify-center',
          { 'pt-12 pb-4 border-t': !isSidebar },
        ]"
      >
        <div class="font-bold text-gray-900 mb-2 text-lg sm:text-xl">
          {{ $t("modules.notification.emptyMessage.heading") }}
        </div>
        <div class="text-gray-500 text-sm font-normal sm:text-md">
          {{ $t("modules.notification.emptyMessage.description") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { DsLiveNotification } from "@devsalsa/vue-core";

import type { PropType } from "vue";

import NotificationCard from "@/modules/notification/components/NotificationCard.vue";

export default defineComponent({
  name: "NotificationList",
  components: {
    NotificationCard,
  },
  props: {
    isSidebar: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Object as PropType<DsLiveNotification[]>,
      required: true,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    disabledNavSidebar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeSidebar", "notificationRead", "deleteNotification"],
  methods: {
    onMarkRead(notificationId: number) {
      this.$emit("notificationRead", notificationId);
    },
    onDelete(notificationId: number) {
      this.$emit("deleteNotification", notificationId);
    },
  },
});
</script>
