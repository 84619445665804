<template>
  <DsModal
    id="contact-support-modal"
    size="md"
    class="contact-support-modal"
    data-testid="contact-support-modal"
    :static="!extra.submitted"
    @closed="onClosed"
    @opened="onOpened"
    @before-open="onBeforeOpen"
  >
    <DsModalHeader
      v-if="!extra.submitted"
      :title="$t('core.shared.components.contactSupport.title')"
      hide-close-icon
    />
    <DsModalBody>
      <DsFlash id="contact-support-flash" />
      <DsForm
        ref="form"
        v-slot="{ meta }"
        autocomplete="off"
        :validation-schema="validationSchema"
        :populating="extra.populating"
        data-testid="contact-support-form"
        @submit="onSubmit"
      >
        <template v-if="!extra.submitted">
          <div v-show="extra.isPublic">
            <DsInput
              id="name"
              ref="name"
              v-model="extra.name"
              name="name"
              :label="
                $t('core.shared.components.contactSupport.form.name.label')
              "
              maxlength="255"
              data-testid="contact-name"
            />
            <DsInput
              id="email"
              v-model="extra.email"
              name="email"
              type="email"
              :label="
                $t('core.shared.components.contactSupport.form.email.label')
              "
              :placeholder="
                $t(
                  'core.shared.components.contactSupport.form.email.placeholder'
                )
              "
              autocomplete="email"
              maxlength="255"
              data-testid="contact-email"
            />
          </div>
          <DsTextarea
            id="message"
            ref="textarea"
            v-model="model.message"
            name="message"
            data-testid="contact-message"
            :label="
              $t('core.shared.components.contactSupport.form.details.label')
            "
            :placeholder="
              $t(
                'core.shared.components.contactSupport.form.details.placeholder'
              )
            "
            maxlength="1200"
            :rows="5"
          />
          <p class="form-textarea-details" data-testid="message-chars-count">
            {{
              $t("core.shared.components.contactSupport.characters", {
                currentCharacters: $string.getLength(model.message),
                characters: 1200,
              })
            }}
          </p>
          <div class="form-actions">
            <DsButton
              outline
              size="lg"
              class="form-actions-cancel"
              :disabled="loading"
              data-testid="cancel-message-button"
              @click="close"
            >
              {{ $t("core.shared.components.contactSupport.buttons.cancel") }}
            </DsButton>
            <DsFormButton
              :loading="loading"
              :disabled="!meta.valid"
              size="lg"
              data-testid="send-message-button"
            >
              {{ $t("core.shared.components.contactSupport.buttons.send") }}
            </DsFormButton>
          </div>
        </template>
        <template v-else>
          <div class="submitted-message-body">
            <DsFlagSolidIcon class="icon" />
            <p class="title" data-testid="submitted-message-title">
              {{ $t("core.shared.components.contactSupport.submitted.title") }}
            </p>
            <p class="message" data-testid="submitted-message-message">
              {{
                $t("core.shared.components.contactSupport.submitted.message")
              }}
            </p>
          </div>
          <DsButton
            size="lg"
            class="w-full"
            data-testid="submitted-message-ok-button"
            @click="close"
          >
            {{ $t("core.shared.components.contactSupport.buttons.ok") }}
          </DsButton>
        </template>
      </DsForm>
    </DsModalBody>
  </DsModal>
</template>

<script lang="ts">
import { defineComponent, markRaw } from "vue";

import {
  DsButton,
  DsFlagSolidIcon,
  DsFlash,
  DsFlashNotifier,
  DsForm,
  DsFormButton,
  DsInput,
  DsModal,
  DsModalBody,
  DsModalHandler,
  DsModalHeader,
  DsTextarea,
} from "@devsalsa/vue-core";

import AccountService from "@/core/modules/account/services/AccountService";
import type {
  AccountInfo,
  ContactFormData,
} from "@/core/modules/account/services/AccountService.types";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import type { FormHandlerData } from "@/core/shared/helpers/FormHandler";
import { FormHandler } from "@/core/shared/helpers/FormHandler";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { SubmissionContext } from "vee-validate";
import { object, string } from "yup";

export default defineComponent({
  name: "ContactSupportFormModal",
  components: {
    DsModal,
    DsModalHeader,
    DsModalBody,
    DsFlash,
    DsButton,
    DsFormButton,
    DsForm,
    DsTextarea,
    DsFlagSolidIcon,
    DsInput,
  },
  emits: ["submit"],
  data(): FormHandlerData {
    return {
      loading: false,
      validationSchema: markRaw(
        object({
          name: string()
            .trim()
            .required(
              this.$t(
                "core.shared.components.contactSupport.form.name.validation.required"
              )
            )
            .min(
              3,
              this.$t("common.error.validation.minStringChars", { min: 3 })
            )
            .max(
              255,
              this.$t("common.error.validation.maxStringChars", { max: 255 })
            ),
          email: string()
            .required(
              this.$t(
                "core.shared.components.contactSupport.form.email.validation.required"
              )
            )
            .email(this.$t("common.form.input.email.validation.invalid"))
            .max(
              255,
              this.$t("common.error.validation.maxStringChars", { max: 255 })
            ),
          message: string()
            .transform((currentValue, oldValue) => {
              return oldValue.replace(/\n/g, "\r\n").trim();
            })
            .min(
              6,
              this.$t(
                "core.shared.components.contactSupport.form.detailsValidation.min",
                { nb: 6 }
              )
            )
            .max(
              1200,
              this.$t(
                "core.shared.components.contactSupport.form.detailsValidation.max",
                { nb: 1200 }
              )
            )
            .required(
              this.$t(
                "core.shared.components.contactSupport.form.detailsValidation.required"
              )
            ),
        })
      ),
      extra: {
        populating: false,
        submitted: false,
        accountInfo: {} as AccountInfo,
        name: "",
        email: "",
        isPublic: true,
      },
      model: {
        message: "",
      },
    };
  },
  methods: {
    async onBeforeOpen() {
      if (AccountHandler.isAuthenticated()) {
        this.extra.accountInfo = await AccountHandler.getInfo();
        this.extra.name = `${this.extra.accountInfo.first_name} ${this.extra.accountInfo.last_name}`;
        this.extra.email = this.extra.accountInfo.email;
        this.extra.isPublic = false;
      }
    },
    onOpened() {
      if (this.extra.isPublic) {
        (this.$refs.name as InstanceType<typeof DsInput>).focus();
      } else {
        (this.$refs.textarea as InstanceType<typeof DsTextarea>).focus();
      }
    },
    async onSubmit(data: ContactFormData, actions: SubmissionContext) {
      this.loading = true;
      DsFlashNotifier.hide("contact-support-flash");
      try {
        const response = await AccountService.contact(
          data.name,
          data.email,
          data.message
        );
        if (response.updated) {
          this.onClosed();
          this.extra.submitted = true;
        }
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        DsFlashNotifier.error(
          ErrorTranslator.translate(error),
          "contact-support-flash"
        );
        FormHandler.setErrors(error, actions);
      } finally {
        this.loading = false;
      }
    },
    close() {
      DsModalHandler.close("contact-support-modal");
    },
    onClosed() {
      this.extra.submitted = false;
      (this.$refs.form as InstanceType<typeof DsForm>).resetForm();
    },
  },
});
</script>

<style lang="scss" scoped>
.contact-support-modal {
  .form-textarea-details {
    @apply mb-5 text-sm font-normal text-gray-500;
  }

  .form-actions {
    @apply grid md:grid-cols-2 w-full md:gap-5 gap-3 pt-5;

    &-cancel {
      @apply md:order-first order-last;
    }
  }

  .posted-video-form {
    @apply flex flex-col;

    .posted-video-info {
      @apply flex gap-6 pb-6;

      &-video {
        @apply w-[148px] bg-black rounded-md;
      }

      &-skeleton {
        @apply w-[148px] rounded-md;
      }
    }

    .posted-video-inputs {
      @apply pt-4 border-t border-gray-300 pb-4;
    }

    .posted-video-action {
      @apply grid md:grid-cols-2 w-full md:gap-5 gap-3 pt-6 border-t border-gray-300;

      &-cancel {
        @apply md:order-first order-last;
      }
    }
  }

  .submitted-message-body {
    @apply flex flex-col items-center;

    .icon {
      @apply h-10 w-10 mt-3 mb-6;
    }

    .title {
      @apply text-xl font-semibold text-gray-900 mb-2;
    }

    .message {
      @apply text-base font-normal text-gray-500 mb-8;
    }
  }
}
</style>
