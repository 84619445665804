export default [
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () =>
      import("@/shared/modules/page-not-found/views/PageNotFound.vue"),
    meta: {
      layout: "PageNotFoundLayout",
      title: "shared.modules.pageNotFound.views.pageNotFound.meta.title",
    },
  },
];
