<template>
  <footer class="unauthenticated-footer">
    <div class="unauthenticated-footer-copyright">
      © {{ date }} JoinBrands.com
      {{ $t("core.shared.components.layout.footer.rights") }}
      <span class="unauthenticated-footer-copyright-version">{{
        version
      }}</span>
    </div>
    <div class="unauthenticated-footer-links">
      <DsButton
        theme-display="link"
        theme-color="gray"
        class="ds-link-no-underline text-left text-sm lg:text-base"
        data-testid="contact-support-sidebar-link"
        @click="onOpenContactModal"
      >
        {{ $t(`modules.settings.contact.sidebarText`) }}
      </DsButton>
      <ExternalFooterLink
        :href="'https://joinbrands.com/privacy'"
        target="_blank"
        rel="nofollow"
      >
        {{ $t("common.privacy") }}
      </ExternalFooterLink>
      <ExternalFooterLink
        :href="'https://joinbrands.com/terms'"
        target="_blank"
        rel="nofollow"
      >
        {{ $t("common.terms") }}
      </ExternalFooterLink>

      <ContactSupportFormModal />
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DsButton, DsModalHandler } from "@devsalsa/vue-core";

import ExternalFooterLink from "@/core/shared/components/Link/ExternalFooterLink.vue";

import ContactSupportFormModal from "@/modules/contact/components/ContactSupportFormModal.vue";

export default defineComponent({
  name: "UnauthenticatedFooter",
  components: { ContactSupportFormModal, ExternalFooterLink, DsButton },
  data() {
    return {
      date: new Date().getFullYear(),
      version: `v${import.meta.env.PACKAGE_VERSION}`,
    };
  },
  methods: {
    onOpenContactModal() {
      DsModalHandler.open("contact-support-modal");
    },
  },
});
</script>

<style lang="scss" scoped>
.unauthenticated-footer {
  @apply w-full bg-gray-50 lg:bg-transparent px-4 py-2 lg:px-8 h-32 sm:h-16
  flex flex-col md:flex-row md:items-center justify-between relative;

  &-copyright {
    @apply text-gray-500 text-sm lg:text-base;

    &-version {
      @apply absolute sm:static right-2 bottom-2 text-gray-500;
    }
  }

  &-links {
    @apply flex flex-col sm:items-center sm:flex-row md:justify-end gap-1 sm:gap-5 sm:grow;
  }
}
</style>
