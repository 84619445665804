import DashboardGuard from "@/modules/dashboard/helpers/DashboardGuard";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      DashboardGuard.withPendingSubscription,
    ],
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/modules/dashboard/views/TheDashboard.vue"),
        meta: { title: "common.route.dashboard.meta" },
      },
    ],
  },
];
